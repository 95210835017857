import { Image } from "theme-ui";
import usePostImage from "./usePostImage";
import Loading from "../Loading";

const PostImage = ({ collection, post, thumb = false }) => {
  const postImage = usePostImage({ collection, post, thumb });
  const width = thumb ? "522px" : "1044px";

  return (
    <div sx={{ width, aspectRatio: "3 / 1", maxWidth: "100%" }}>
      {postImage ? (
        <Image variant={thumb ? "thumb" : "curved"} src={postImage} alt="" />
      ) : (
        <Loading bg="secondary" color="primary" />
      )}
    </div>
  );
};

export default PostImage;

import { Box } from "theme-ui";

const Footer = () => (
  <Box as="footer" p="md" sx={{ textAlign: "center" }}>
    &copy; Treats Children's Charity {new Date().getFullYear()}. Registered
    charity No. 700799
  </Box>
);

export default Footer;

import { useFirestore, useFirestoreCollectionData } from "reactfire";
import { collection, query, orderBy, limit } from "firebase/firestore";

const useNews = (summary = false) => {
  if (typeof window === "undefined") {
    return { news: [], status: "loading" };
  }

  const firestore = useFirestore();
  const newsCollection = collection(firestore, "news");
  let newsQuery;
  if (summary) {
    newsQuery = query(newsCollection, orderBy("createdAt", "desc"), limit(2));
  } else {
    newsQuery = query(newsCollection);
  }

  const { status, data: news } = useFirestoreCollectionData(newsQuery, {
    idField: "id",
  });

  return { status, news };
};

export default useNews;

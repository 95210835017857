import { Button } from "theme-ui";
import { logEvent } from "firebase/analytics";
import { useAnalytics } from "reactfire";

const DonateButtonBase = ({ onClick = () => {} }) => (
  <Button
    variant="primary"
    as="a"
    href="https://www.paypal.com/donate/?hosted_button_id=TGVGR3MRDDMNG"
    target="_blank"
    onClick={onClick}
  >
    Donate
  </Button>
);

const DonateButton = () => {
  if (typeof window === "undefined") {
    return <DonateButtonBase />;
  }

  const analytics = useAnalytics();
  const onClick = () => {
    logEvent(analytics, "donate");
  };

  return <DonateButtonBase onClick={onClick} />;
};

export default DonateButton;

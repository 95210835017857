import Link from "next/link";
import { Box, Link as ThemedLink } from "theme-ui";
import { Themed } from "@theme-ui/mdx";
import PostImage from "./PostImage";

const PostCard = ({ collection, post, onClick }) => (
  <Box>
    <Link
      href={`/${collection}/post?id=${post.id}`}
      key={`${post.id}`}
      passHref
      legacyBehavior
    >
      <ThemedLink onClick={onClick} variant="card">
        <PostImage collection={collection} post={post} thumb />
        <Box p="md" bg="secondary" sx={{ flexGrow: 1 }}>
          <Themed.h3>{post.title} &rarr;</Themed.h3>
          <Themed.p>{post.blurb}</Themed.p>
        </Box>
      </ThemedLink>
    </Link>
  </Box>
);

export default PostCard;

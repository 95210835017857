import { Themed } from "@theme-ui/mdx";

const Heading = ({ children }) => (
  <>
    <Themed.h2 sx={{ textAlign: "center", mb: ["md", null, "lg"] }}>
      {children}
      <div
        sx={{
          margin: "30px auto 0 auto",
          height: "1px",
          width: "500px",
          maxWidth: "80%",
          backgroundColor: "text",
        }}
      ></div>
      <div
        sx={{
          margin: "-2px auto 0 auto",
          height: "3px",
          width: "150px",
          maxWidth: "20%",
          backgroundColor: "text",
        }}
      ></div>
    </Themed.h2>
  </>
);

export default Heading;

import Link from "next/link";
import { useState } from "react";
import { Container, Flex, Box, NavLink, Image, MenuButton } from "theme-ui";

const Header = ({ variant = "container" }) => {
  const [menuOpen, setMenuOpen] = useState(false);
  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  return (
    <Container
      as="header"
      variant={variant}
      pb={[0, null, "md"]}
      pt={["sm", null, "md"]}
      px={0}
    >
      <Flex
        sx={{
          flexDirection: ["column", null, "row"],
          justifyContent: "space-between",
        }}
      >
        <Box mx={["auto", null, 0]}>
          <Link href="/" passHref legacyBehavior>
            <a>
              <Image
                sx={{ width: ["200px", null, null, "300px", "400px"] }}
                src="/logo.webp"
                alt="Treats Logo"
              />
            </a>
          </Link>
        </Box>
        <Box sx={{ bg: "primary", display: ["block", null, "none"], mt: "sm" }}>
          <MenuButton onClick={toggleMenu} aria-label="Toggle Menu" />
        </Box>
        <Flex
          as="nav"
          sx={{
            display: [menuOpen ? "flex" : "none", null, "flex"],
            justifyContent: ["start", null, "end"],
            alignItems: ["start", null, "center"],
            flexDirection: ["column", null, "row"],
            bg: ["primary", null, "transparent"],
            py: ["sm", null, 0],
            px: ["md", null, 0],
          }}
        >
          <Link href="/" passHref legacyBehavior>
            <NavLink>Home</NavLink>
          </Link>
          <Link href="/news" passHref legacyBehavior>
            <NavLink>News</NavLink>
          </Link>
          <Link href="/events" passHref legacyBehavior>
            <NavLink>Events</NavLink>
          </Link>
          <Link href="/the-team" passHref legacyBehavior>
            <NavLink>The team</NavLink>
          </Link>
          <Link href="/#contact-us" passHref legacyBehavior>
            <NavLink>Contact us</NavLink>
          </Link>
        </Flex>
      </Flex>
    </Container>
  );
};

export default Header;

import { Flex } from "theme-ui";

const doubleBounceStyles = (color) => ({
  width: "100%",
  height: "100%",
  borderRadius: "50%",
  backgroundColor: color,
  opacity: 0.6,
  position: "absolute",
  top: 0,
  left: 0,
  WebkitAnimation: "sk-bounce 2.0s infinite ease-in-out",
  animation: "sk-bounce 2.0s infinite ease-in-out",
  "@-webkit-keyframes sk-bounce": {
    "0%, 100%": { WebkitTransform: "scale(0.0)" },
    "50%": { WebkitTransform: "scale(1.0)" },
  },
});

const Loading = ({ bg, color = "secondary" }) => (
  <Flex
    sx={{ bg, height: "100%", justifyContent: "center", alignItems: "center" }}
  >
    <div
      sx={{
        aspectRatio: "1",
        maxHeight: "50%",
        height: "80px",
        position: "relative",
      }}
    >
      <div sx={doubleBounceStyles(color)} />
      <div
        sx={{
          ...doubleBounceStyles(color),
          WebkitAnimationDelay: "-1.0s",
          animationDelay: "-1.0s",
        }}
      />
    </div>
  </Flex>
);

export default Loading;

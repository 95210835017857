import Link from "next/link";
import Header from "../components/Header";
import Footer from "../components/Footer";
import { Box, Container, Grid, Image, Link as ThemedLink } from "theme-ui";
import { Themed } from "@theme-ui/mdx";
import PostCard from "../components/PostCard";
import Heading from "../components/Heading";
import DonateButton from "../components/DonateButton";
import useNews from "../utils/useNews";
import useEvents from "../utils/useEvents";

const Home = () => {
  const { news } = useNews(true);
  const { events } = useEvents(true);

  return (
    <>
      <Container variant="page">
        <Header variant="muted" />
        <main>
          <Container
            sx={{
              pt: ["md", null, "60px"],
              pb: ["md", null, "150px"],
            }}
            variant="muted"
          >
            <Grid gap={["md", null, "lg"]} columns={[1, null, 2]}>
              <Box>
                <Themed.h1>Helping Derby's Children</Themed.h1>
                <Themed.p>
                  Bringing a smile to underprivilaged children in Derby and
                  Derbyshire.
                </Themed.p>
                <DonateButton />
              </Box>
              <Box>
                <Image
                  variant="curved"
                  src="/home-background.webp"
                  alt="Photo of children"
                />
              </Box>
            </Grid>
          </Container>
          <Container>
            <Heading>About us</Heading>
            <Grid gap={["md", null, "lg"]} columns={[1, null, 2]}>
              <Box>
                <Themed.h3>Our purpose</Themed.h3>
                <Themed.p>
                  For over 35 years children across Derbyshire have benefitted
                  from a gift from Treats. Responding to requests we receive
                  from schools; support workers, and organisations, our
                  donations vary, and have included:
                </Themed.p>
                <Themed.ul>
                  <Themed.li>
                    A specially trained puppy to live with an autistic teenager
                  </Themed.li>
                  <Themed.li>
                    The purchase of 3 specially adapted mini buses for disabled
                    children
                  </Themed.li>
                  <Themed.li>
                    Specialised playground equipment, toys, iPads and
                    televisions for severely disabled children
                  </Themed.li>
                  <Themed.li>
                    Annual donation to fund the Derby Foster Carers Association
                    Christmas party
                  </Themed.li>
                  <Themed.li>
                    Treats Christmas Annual Appeal. Deserving children are
                    nominated by their primary school. Presents are then
                    purchased, wrapped, and delivered to school by the Treats
                    Team.
                  </Themed.li>
                </Themed.ul>
              </Box>
              <Image
                variant="curved"
                src="/purpose.webp"
                alt="Photo of children"
              />
              <Image variant="curved" src="/gifts.webp" alt="Photo of gifts" />
              <Box>
                <Themed.h3>What we do</Themed.h3>
                <Themed.p>Our support is funded by money raised via:</Themed.p>
                <Themed.ul>
                  <Themed.li>
                    A rolling programme of{" "}
                    <Link href="/#events" passHref legacyBehavior>
                      <ThemedLink>fund raising events</ThemedLink>
                    </Link>
                    , including Ladies Lunches, Golf Days and Galas.
                  </Themed.li>
                  <Themed.li>
                    <strong>Our fabulous friends and supporters</strong>. The
                    ongoing generosity of organisations, sponsors, and
                    supporters across the local community enable Treats to
                    achieve our purpose.
                  </Themed.li>
                </Themed.ul>
              </Box>
            </Grid>
          </Container>
          <Container variant="muted">
            <Heading>Latest News</Heading>
            {(news && news.length && (
              <Grid gap={["md", null, "lg"]} columns={[1, null, 2]}>
                {news.map((post) => (
                  <PostCard collection="news" post={post} key={post.id} />
                ))}
              </Grid>
            )) || (
              <Themed.p sx={{ textAlign: "center" }}>
                Nothing here yet. Check again soon!
              </Themed.p>
            )}
            <Box sx={{ textAlign: "right", mt: "sm" }}>
              <Link href="/news" passHref legacyBehavior>
                <ThemedLink>View all &rarr;</ThemedLink>
              </Link>
            </Box>
          </Container>
          <Container sx={{ textAlign: "center" }}>
            <Themed.h3 sx={{ textAlign: "center", mb: ["md", null, "lg"] }}>
              Help us bring a smile to the children of Derbyshire
            </Themed.h3>
            <DonateButton />
          </Container>
          <Container variant="muted">
            <Heading>Latest Events</Heading>
            {(events && events.length && (
              <Grid gap={["md", null, "lg"]} columns={[1, null, 2]}>
                {events.map((post) => (
                  <PostCard collection="events" post={post} key={post.id} />
                ))}
              </Grid>
            )) || (
              <Themed.p sx={{ textAlign: "center" }}>
                Nothing here yet. Check again soon!
              </Themed.p>
            )}
            <Box sx={{ textAlign: "right", mt: "sm" }}>
              <Link href="/events" passHref legacyBehavior>
                <ThemedLink>View all &rarr;</ThemedLink>
              </Link>
            </Box>
          </Container>
          <Container sx={{ textAlign: "center" }} id="contact-us">
            <Themed.h3>We&apos;d love to hear from you!</Themed.h3>
            <Themed.p>
              If you&apos;d like to find out more about Treats, can support us,
              or you are an organisation and know of a child or cause who could
              benefit from our support, please email:
            </Themed.p>
            <ThemedLink
              sx={{ fontSize: ["sm", null, "md"], wordBreak: "break-word" }}
              href="mailto:treatschildrencharity@gmail.com"
            >
              treatschildrencharity@gmail.com
            </ThemedLink>
          </Container>
        </main>
      </Container>
      <Footer />
    </>
  );
};

export default Home;

import { useState, useEffect } from "react";
import { getStorage, ref, getDownloadURL } from "firebase/storage";

const usePostImage = ({ collection, post, thumb = false }) => {
  const storage = getStorage();
  let [postImage, setPostImage] = useState("");
  const fileName = thumb ? post.image.thumbFileName : post.image.fileName;

  useEffect(() => {
    getPostImage();
  }, [post]);

  const getPostImage = async () => {
    let downloadURL = "";
    if (post?.image?.resized) {
      const storageRef = ref(storage, `/${collection}/${post.id}/${fileName}`);
      downloadURL = await getDownloadURL(storageRef);
    }
    setPostImage(downloadURL);
  };

  return postImage;
};

export default usePostImage;

import { useFirestore, useFirestoreCollectionData } from "reactfire";
import { collection, query, orderBy, limit } from "firebase/firestore";

const useEvents = (summary = false) => {
  if (typeof window === "undefined") {
    return { events: [], status: "loading" };
  }

  const firestore = useFirestore();
  const eventsCollection = collection(firestore, "events");
  let eventsQuery;
  if (summary) {
    eventsQuery = query(
      eventsCollection,
      orderBy("createdAt", "desc"),
      limit(2)
    );
  } else {
    eventsQuery = query(eventsCollection);
  }

  const { status, data: events } = useFirestoreCollectionData(eventsQuery, {
    idField: "id",
  });

  return { status, events };
};

export default useEvents;
